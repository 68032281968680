const https = require('https');

// ** App Config
import appConfig from '@configs/appConfig'

// ** Utils
import SecurityUtils from '@src/services/SecurityUtils'
import {getUserData} from '@utils'

const licensesUrlApi = appConfig.licensesUrl[appConfig.licensesUrl.env] + "/api/web/v1"

const agent = new https.Agent({
    rejectUnauthorized: false,
    requestCert: false,
    agent: false,
});

const LicensingServerApi = {
    generateRootAxiosParams: function(method, urlPath, params, payload) {
        let p = this.generateAxiosParams(method, urlPath, params, payload)
        let position = p.url.search(/\/api/i)
        let rootPath = p.url.substring(0, position) + urlPath
        p.url = rootPath
        return p
    },
    generateAxiosParams: function(method, urlPath, params, payload) {
        let session = getUserData()

        let p = {
            method,
            url:  licensesUrlApi + urlPath,
            httpsAgent: agent,
            params: {},
            headers: {},
            timeout: 2000,
            data: payload,
        }

        if (urlPath !== '/CSRFToken') {
            const csrftoken = SecurityUtils.getCsrf()
            if (csrftoken) {
                p.headers['NBS-CSRF-TOKEN'] = csrftoken
                SecurityUtils.updateCsrf()
            }
        }

        if (session) {
            p.headers['NBS-SESSION-TOKEN'] = session?.session_key
        }
        
        if (appConfig.iobAdminKey) {
            p.headers['NBS-ADMIN-KEY'] = appConfig.iobAdminKey
        }
        if (params?.clientId) {
            p.params['clientId'] = params.clientId
        }
        if (params?.projectId) {
            p.params['projectId'] = params.projectId
        }
        if (params?.apiKey) {
            p.params['apiKey'] = params.apiKey
        }
        if (params?.label) {
            p.params['label'] = params.label
        }
        if (params?.email) {
            p.params['email'] = params.email
        }
        if (params?.passwd) {
            p.params['passwd'] = params.passwd
        }
        if (params?.id) {
            p.params['id'] = params.id
        }
        if (params?.name) {
            p.params['name'] = params.name
        }
        return p
    },
};
export default LicensingServerApi;

