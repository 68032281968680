// ** Config
import appConfig from '@configs/appConfig'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Reactstrap Imports
import { Table } from 'reactstrap'

import moment from 'moment';
//import { parse } from 'path-browserify';

// ** Third Party Components
import {Thermometer, Zap, Cloud, CloudOff, RefreshCw, Lock, Unlock} from 'react-feather'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getUserLocale = () => {
  let session = getUserData()
  if (session) {
    if (session.locale === 'es') {
      return 'es'
    }
  }
  return 'en'
}
export const getMenuByLocale = () => {
  let locale = getUserLocale()
  if (locale == 'es') {
    return {
      "prj_imu": "Proyecto IMU",
      "prj_imu.dashboard": "Vista Global",
      "prj_imu.list": "Dispositivos",
      "prj_imu.logs": "Registros",
      "prj_echarger": "Proyecto E-Charger",
    }
  }
  return {
    "prj_imu": "IMU Project",
    "prj_imu.dashboard": "Dashboard",
    "prj_imu.list": "IMU List",
    "prj_imu.logs": "IMU Logs",
    "prj_echarger": "E-Charger Project",
  }
}
export const getUserLanguageStr = () => {
  let session = getUserData()
  if (session) {
    if (session.locale === 'es') {
      return 'Español'
    }
    if (session.locale === 'en') {
      return 'English'
    }
  }
  return ''
}
export const isThemeLight = () => {
  const v = JSON.parse(localStorage.getItem('skin'))
  return v === null || v === 'light'
}

export const isThemeDark = () => !isThemeLight()

export const isPhoneScreen = () => {
  if ( screen.width <= 480 ) {
    return true
  }
}

export const hasSessionAccessToSection = (session, id) => {
  return session && (session.access.indexOf('all') >= 0 || session.access.indexOf(id) >= 0)
}

export const isAdminRole = () => {
  let session = getUserData()
  return session && session.role.toLowerCase() === 'admin'
}

export const isClientRole = () => {
  let session = getUserData()
  return session && session.role.toLowerCase() === 'client'
}

export const isDemoUser = () => {
  let session = getUserData()
  return session && session.email.toLowerCase().indexOf('demo') >= 0
}

export const isDokuosClient = () => {
  let session = getUserData()
  return session && session.client_name.toLowerCase() === 'dokuos'
}

export const getClientLogo = () => {
  let session = getUserData()
  if (session && session.email.toLowerCase().indexOf('dokuos.com') >= 0) {
    return isThemeLight() ? themeConfig.app.appDokuosLogoImageDark : themeConfig.app.appDokuosLogoImage;
  }
  return isThemeLight() ? themeConfig.app.appLogoImageDark : themeConfig.app.appLogoImage;
}

export const getClientLogoSize = () => {
  let session = getUserData()
  if (session && session.email.toLowerCase().indexOf('dokuos.com') >= 0) {
    return themeConfig.app.appDokuosLogoWidth;
  }
  return themeConfig.app.appLogoWidth;
}

export const EVENT_TYPE = {
  IMU_STATUS: 'IMU_STATUS',
  IMU_DOOR: 'IMU_DOOR',
  IMU_TEMPERATURE: 'IMU_TEMP',
  IMU_POWER: 'IMU_POWER',
  IMU_REBOOT: 'IMU_REBOOT',
}

export const tsToDateString = (ts) => {
  return ts ? moment(ts * 1000).format(appConfig.dateFormat) : null
}

export const tsFromDate = (d) => {
  return d && parseInt(d.getTime()/1000)
}

export const initialDayTsFromDate = (d) => {
  let _d = moment(d*1000)
  let k = moment().set('year', _d.year()).set('month', _d.month()).set('date', _d.date()).set('hour', 0).set('minute', 0).set('second', 0)
  return tsFromDate(k.toDate())
}

export const nDaysBeforeTsFromDate = (d, n) => {
  let count = n ? 24*n : 24
  return tsFromDate(moment(d*1000).subtract(count, 'hours').toDate())
}

export const listToSelectList = (list, labelAttr, valueAttr) => {
  let val = []
  if (list) {
    list.map(el => {
        val.push({
            ...el,
            value: el[valueAttr],
            label: el[labelAttr]
        })
    });
  }
  return val;
}

export const translateImuLogSensor = (logEntry) => {
  if ( !logEntry ) { return '' }
  let eventType = logEntry.type
  let eventSensorId = logEntry.sensor_id
  if (eventType == EVENT_TYPE.IMU_POWER) {
    if (eventSensorId == IMU_POWER_SENSOR_ID.BATTERY) { return '(POWER SUPPLY OUTPUT DC)' }
    if (eventSensorId == IMU_POWER_SENSOR_ID.IMU) { return '(INPUT AC)' }
    if (eventSensorId == IMU_POWER_SENSOR_ID.UPS) { return '(UPS OUTPUT)' }
  }
}

export const convertImuLogTypeToIcon = (logEntry) => {
  if ( !logEntry ) { return }
  let eventType = logEntry.type
  let data = logEntry.details
  if (eventType == EVENT_TYPE.IMU_TEMPERATURE) {
    return <Thermometer/>
  }
  if (eventType == EVENT_TYPE.IMU_POWER) {
    return <Zap/>
  }
  if (eventType == EVENT_TYPE.IMU_STATUS) {
    return <Cloud/>
  }
  if (eventType == EVENT_TYPE.IMU_REBOOT) {
    return <RefreshCw/>
  }
  if (eventType == EVENT_TYPE.IMU_DOOR) {
    if (logEntry?.details) {
      return logEntry?.details?.result == 0 ? <Lock/> : <Unlock/>
    }
  }
}

export const convertImuLogDataToString = (logEntry) => {
  if ( !logEntry ) { return }
  let res = []
  let firstColumnStyle= {paddingLeft: 0}
  let eventId = logEntry.id
  let eventType = logEntry.type
  let data = logEntry.details
  let sensorStr = translateImuLogSensor(logEntry)

  if (eventType == EVENT_TYPE.IMU_STATUS) {
    if (data.result == 0) {
      return <Table><tbody><tr><td style={firstColumnStyle}>Online</td></tr></tbody></Table>
    } else if (data.result == 1) {
      return <Table><tbody><tr><td>Offline</td></tr></tbody></Table>
    } else {
      return ''
    }
  }
  
  if (eventType == EVENT_TYPE.IMU_DOOR) {
    if (data.result == 1) {
      return 'Opened'
    } else if (data.result == 0) {
      return 'Closed'
    } else {
      return ''
    }
  }
  
  if (eventType == EVENT_TYPE.IMU_TEMPERATURE) {
    res.push(<td style={firstColumnStyle} key={eventId+'temp'}>{'Temp: ' + data.temp}º</td>)
    res.push(<td key={eventId+'hum'}>{'Humidity: ' + data.humidity}%</td>)
    return <Table><tbody><tr>{res}</tr></tbody></Table>
  }
  
  if (eventType == EVENT_TYPE.IMU_POWER) {
    res.push(<td style={firstColumnStyle} key={eventId+'psu'}>{'PSU: ' + parseFloat(data.psu).toFixed(3)}V</td>)
    res.push(<td key={eventId+'shunt'}>{'Shunt: ' + parseFloat(data.shunt).toFixed(3)}</td>)
    res.push(<td key={eventId+'power'}>{'Power: ' + parseFloat(data.power).toFixed(3)}V</td>)
    res.push(<td key={eventId+'current'}>{'Current: ' + parseFloat(data.current).toFixed(3)}w</td>)
    res.push(<td key={eventId+'sensor'}>{sensorStr}</td>)
    return <Table><tbody><tr>{res}</tr></tbody></Table>
  }

  if (eventType == EVENT_TYPE.IMU_REBOOT) {
    return 'IMU device has been rebooted'
  }

  Object.keys(data).map(function(key, index) {
    res.push(<div key={eventId+index}>{key + ': ' + data[key]}</div>)
  })
  return res
}

export const updateFilterBadgeList = (filterBadges, filter, filterNewValue, filterType) => {
  let l = [...filterBadges]
  if (filterNewValue && filterNewValue.value !== '') {
    if (filter) {
      l = l.filter(el => el?.id !== filterType)
    }
    if (filterNewValue.label) {
      l.push({label:filterNewValue.label, id:filterType})
    }
  } else {
    l = l.filter(el => el?.id !== filterType)
  }
  return l
}

export const removeFilterBadge = (filterBadges, filterType) => {
  let l = [...filterBadges].filter(el => el?.id !== filterType)
  return l
}

export const FILTERS = {
  SEARCH: 'search',
  POWER: 'power',
  STATUS: 'status',
  PROJECT: 'project',
  EVENT_TYPE: 'event_type',
}

export const SECTIONS = {
  NBS_LICENSES: 'licenses',
  NBS_USERS: 'users',
  NBS_PROJECTS: 'projects',
  NBS_IMU_PROJECT: 'imuProject',
  NBS_CHARGER_PROJECT: 'chargerProject',
  NBS_RADIUS: 'radius',
}

export const IMU_STATUS = {
  OFFLINE: 1,
  ONLINE: 2,
  REBOOTING: 3,
}

export const IMU_POWER_SUPPLY_STATUS = {
  EXTERNAL: 1,
  SAI_HIGH: 2,
  SAI_LOW: 3,
}

export const IMU_POWER_SENSOR_ID = {
  BATTERY: 1,
  IMU: 2,
  UPS: 3,
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  //if (userRole === 'admin') return '/'
  //if (userRole === 'client') return '/access-control'
  if (appConfig.debug) {
    console.log('getHomeRouteForLoggedInUser.userRole=' + userRole)
  }
  if (userRole) return '/'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
    
    red: {
      light: '#ededed',
      dark: '#ededed',
    },
    yellow: {
      light: '#ededed',
      dark: '#ededed',
    },
    green: {
      light: '#ededed',
      dark: '#ededed',
    },
  }
})
