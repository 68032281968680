// You can customize the template with the help of this file

const appConfig = {
  debug: false,
  rowsPerPage: 50,
  maxEntriesPerChart: 60,
  dateFormat: "DD-MMM-YYYY H:mm:ss",
  googleMapsApiKey: "AIzaSyAPJ_FU_eIi3eYw2vWqSdXlccYX4IGkmuE",
  radiusUrl: {
    env: 'qa',
    prod: '',
    qa: 'https://radapi.numbytes.com/api/v1',
    devVM: 'http://192.168.1.33:22022/api/v1',
    devLocal: '',
  },
  licensesUrl: {
    env: 'qa',
    prod: '',
    qa: 'https://licensing.numbytes.com',
    qa2: 'http://auth.numbytes.link',
    devVM: 'http://192.168.1.31:12022',
    devLocal: 'http://localhost:12022',
  },
  ImuMonitorUrl: {
    env: 'qa',
    prod: '',
    qa: 'https://monitor1.numbytes.com',
    devVM: 'http://192.168.1.31:22023',
    devLocal: 'http://localhost:22023',
  },
  //TODO: iobAdminKey is DEPRECATED for security reasons. The authentication of the request need to be done using the NBS-SESSION-KEY
  //iobAdminKey: 'bdtalV2ILWHCSMaL3uA1RkYd6eWBzKDNKqlniUcE3wY4bqQl4UAxihbJdRVW8LrJoeRZWGMG3GBCucufXBEir3xsC46bnftPM4TVi5q0mCOKxIJyu25vRx0a9fSDF65r',
  colors: {
    light: {
      primary: '#00dfa1',
      secondary: '#1d6a76',
      text: '#625f6e',
    },
    dark: {
      primary: '#00dfa1',
      secondary: '#1d6a76',
      text: '#d0d2d6',
    }
  }
}

export default appConfig
